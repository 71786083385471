import { DiscountWidget } from "@/components/invoices/DiscountWidget";
import { Button } from "@/components/pcwebapp/Button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { getApi } from "@/utils/api";
import { eligibleInvoiceToInvoice } from "@/utils/shims/eligibleInvoiceToInvoice";
import { cn } from "@/utils/ui";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { invoiceHelper, momentjsFormat } from "@progresspay-next/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Spin } from "antd";
import currency from "currency.js";
import { AlertTriangle, ArrowUp, Check, Loader } from "lucide-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryEligibleInvoiceByIdNonAdmin } from "../../utils/query";
import { TermsCheckbox } from "@/components/misc/TermsCheckbox";

type EligibleInvoiceEmbeddedModalProps = {};

const SuccessScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center my-20">
      <Check className="h-16 w-16 text-sky-500" />
      <div className="text-lg font-semibold mt-4">Early Payment Requested</div>
      <div className="text-slate-400 text-center mt-2">
        Now you can close the this window.
      </div>
    </div>
  );
};

export const EligibleInvoiceEmbeddedModal: (
  props: EligibleInvoiceEmbeddedModalProps
) => JSX.Element | null = () => {
  const { id: pasId } = useParams<{ id: string }>();
  const queryCient = useQueryClient();
  const [chosenDiscount, setChosenDiscount] = useState<{
    payrun_date: string;
    discount: number;
  }>();
  const eligibleInvoiceDetailQuery = useQueryEligibleInvoiceByIdNonAdmin(
    pasId as string,
    {
      staleTime: Infinity,
    }
  );

  // Do not show discount widget if there are no discounts configured
  // Also do not show discount widget if it's in submitted state
  const isShowingDiscountReady = !!eligibleInvoiceDetailQuery.data?.discounts;
  const eligibleInvoiceShimedToInvoice = eligibleInvoiceDetailQuery.data
    ? eligibleInvoiceToInvoice(eligibleInvoiceDetailQuery.data)
    : null;

  const onCancel = useCallback(() => {
    window.parent.postMessage("EARLY_PAYMENT_REQUEST_CANCELLED", "*");
  }, []);

  const api = getApi();
  const requestMutation = useMutation({
    mutationFn: api.requestEarlyPaymentOnEligibleInvoice,
    onSuccess: (response) => {
      window.parent.postMessage("EARLY_PAYMENT_REQUEST_SUCCESS", "*");
    },
    onError: (error: Error) => {
      window.parent.postMessage("EARLY_PAYMENT_REQUEST_ERROR", "*");
    },
  });

  const [isAccepted, setIsAccepted] = useState(false);

  const [isShowingTip, setIsShowingTip] = useState(false);
  const termsTextRawHtml = invoiceHelper.generateTermsTextRawHtml(
    {
      scName: eligibleInvoiceDetailQuery.data?.scName || "",
      gcName: eligibleInvoiceDetailQuery.data?.gcName || "",
    },
    eligibleInvoiceDetailQuery.data?.status === "APPROVED"
  );

  const onRequestEarlyPayment = useCallback(() => {
    if (isAccepted) {
      requestMutation.mutate({
        pas_id: pasId!,
        discount: chosenDiscount!,
        terms_accepted: isAccepted,
      });
    } else {
      setIsShowingTip(true);
    }
  }, [chosenDiscount, pasId, requestMutation, isAccepted]);

  const [termsContainerRef] = useAutoAnimate();

  return (
    <div className="-mt-4 py-4">
      {eligibleInvoiceDetailQuery.isSuccess ? (
        <>
          <div className="">
            <div className="px-6">
              <div className="flex flex-row justify-center bg-sky-50 py-10">
                <div className="px-2 flex-1">
                  <div className="text-lg text-center">Amount (incl. GST)</div>
                  <div className="text-center">
                    {currency(
                      eligibleInvoiceDetailQuery.data
                        ?.amountIncludingGST as number
                    ).format()}
                  </div>
                </div>
                <div className="px-2 flex-1">
                  <div className="text-lg text-center">Original Due Date</div>
                  <div className="text-center">
                    {moment(
                      eligibleInvoiceDetailQuery.data?.originalDueDate,
                      momentjsFormat.dateDB
                    ).format(momentjsFormat.date)}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className={`px-6`}>
                {isShowingDiscountReady ? (
                  <div>
                    <DiscountWidget
                      type={
                        eligibleInvoiceDetailQuery.data.discounts!.display_type
                      }
                      invoice={eligibleInvoiceShimedToInvoice}
                      discountConfiguration={
                        eligibleInvoiceDetailQuery.data.discounts!.payment_runs!
                      }
                      dynamicDiscountConfiguration={
                        eligibleInvoiceDetailQuery.data.discounts!
                          .dynamic_discounts
                      }
                      onChange={(discount) => {
                        setChosenDiscount(
                          discount as {
                            payrun_date: string;
                            discount: number;
                          }
                        );
                      }}
                    />
                    <div ref={termsContainerRef}>
                      {!!chosenDiscount && (
                        <div className={cn("my-4")}>
                          <TermsCheckbox
                            checked={isAccepted}
                            onChange={(e) => {
                              setIsAccepted(e.target.checked);
                            }}
                            termsTextRawHtml={termsTextRawHtml}
                            isShowingError={isShowingTip}
                            setIsShowingTermsError={setIsShowingTip}
                          ></TermsCheckbox>
                        </div>
                      )}
                    </div>
                    <div className="mt-8 mb-4">
                      <div className="text-red-500 my-2 text-right">
                        {requestMutation.isError &&
                          (requestMutation.error.message ||
                            "There was an error processing your request")}
                      </div>
                      <div className="flex flex-row justify-between items-center gap-2">
                        <Button onClick={onCancel} variant={"outline"}>
                          Keep Original Due Date
                        </Button>
                        <Button
                          disabled={
                            !chosenDiscount ||
                            requestMutation.isPending ||
                            requestMutation.isSuccess
                          }
                          onClick={onRequestEarlyPayment}
                        >
                          <span className="inline-flex gap-2 items-center">
                            <span>Request Early Payment</span>
                            {requestMutation.isPending && (
                              <Loader className="h-4 w-4 animate-spin" />
                            )}
                            {requestMutation.isSuccess && (
                              <Check className="h-4 w-4" />
                            )}
                          </span>
                        </Button>
                      </div>
                    </div>

                    {/* <div className="text-slate-400 text-sm mb-8">
                      This is a discount offer you are making to{" "}
                      <b>{eligibleInvoiceDetailQuery.data?.gcName}</b> in
                      exchange for being paid earlier than agreed.{" "}
                      <b>{eligibleInvoiceDetailQuery.data?.gcName}</b> still
                      needs to accept your offer and pay your claim by this new
                      date. If they don’t, the amount will revert to the
                      original amount due by the original due date.
                    </div> */}
                  </div>
                ) : (
                  <Alert>
                    <AlertTriangle className="h-4 w-4" />
                    <AlertDescription>
                      Discount not configured!
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-row items-center justify-center min-h-16">
          <Spin />
        </div>
      )}
    </div>
  );
};
