import { Button } from "./button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./dialog";

export const ConfirmDialog = ({
  open,
  onOpenChange,
  callback,
  title = 'Are you sure?',
  children = '',
}: {
  open: boolean,
  onOpenChange: (v:boolean) => void,
  callback: () => void,
  title?: React.ReactNode,
  children?: React.ReactNode,
}) => {

  return <Dialog open={open} onOpenChange={v => {
      onOpenChange(v)
    }}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="text-xl">{title}</DialogTitle>
      </DialogHeader>
      {children}
      <DialogFooter>
        <DialogTrigger asChild>
          <Button>Cancel</Button>
        </DialogTrigger>
        <Button onClick={() => {
          callback();
          onOpenChange(false);
        }}>Confirm</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
}